import React, { useEffect, useState } from "react";
import SiteLang from "./SiteLang";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import useRoutesHelper from "../hooks/useRoutesHelper";
import { toAbsoluteUrl } from "../helpers/assetHelper";

const navbarPages = [
  {
    name: "navbar_home",
    to: "/",
  },
  {
    name: "navbar_contact",
    to: "contact",
  },
  {
    name: "navbar_career",
    to: "career",
  },
  {
    name: "navbar_projects",
    to: "projects",
  },
  {
    name: "MARKETING",
    to: "marketing",
  },
  {
    name: "WMS",
    to: "warehouse-management-system",
  },
];

function Navbar() {
  const { t: translate } = useTranslation();
  const { currentUrl } = useRoutesHelper();
  const [navbar, setNavbar] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const navbarChangeBgColor = () => {
    if (window.scrollY >= 180) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", navbarChangeBgColor);

  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    const paramsStartingIndex = currentUrl.indexOf("/");

    const getSlicedString = () => {
      if (paramsStartingIndex > 0) {
        return currentUrl.substring(0, paramsStartingIndex);
      }
      return currentUrl;
    };

    setActiveLink(`${getSlicedString()}`);
  }, [currentUrl]);

  const handleShowNav = () => {
    setShowNav(!showNav);
  };

  return (
    <nav
      className={`${
        navbar ? "bg-white drop-shadow-md" : "bg-white border-b"
      } fixed top-0 w-full z-50 ease-out duration-500 font-semibold`}
    >
      <div className="max-w-7xl mx-auto flex flex-row justify-between items-center">
        <Link to="/">
          <img
            src={toAbsoluteUrl("/assets/SSNTAssets/ssnt-logo.svg")}
            alt="ssnt-logo"
            className="hidden xl:flex w-[100px] py-4"
          />
        </Link>
        <ul className="hidden xl:flex md:flex-row items-center ">
          {navbarPages.map((navbarPage) => {
            return (
              <Link
                key={navbarPage.to}
                to={navbarPage.to}
                className={`navbar-page-btn group`}
              >
                {translate(navbarPage.name)}

                {navbarPage.to === activeLink ? (
                  <span className="block max-w-full transition-all duration-500 h-0.5 bg-primary-light-color" />
                ) : (
                  <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-primary-light-color" />
                )}
              </Link>
            );
          })}
          <SiteLang
            wrapperClassName="px-2 py-2 h-auto hidden xl:flex "
            dropdownClassName={`flex flex-col  bg-secondary-color bg-opacity-30 rounded-md px-2 py-2  absolute  ${
              navbar ? "top-[70px]" : "top-[60px]"
            }  cursor-pointer ease-out duration-500`}
          />
        </ul>
      </div>

      <div className="flex justify-between items-center px-5">
        <Link to="/">
          <img
            src={toAbsoluteUrl("/assets/SSNTAssets/ssnt-logo.svg")}
            alt="ssnt-logo"
            className="xl:hidden flex w-[100px] py-4"
          />
        </Link>
        <div onClick={handleShowNav} className="xl:hidden">
          {!showNav ? (
            <AiOutlineMenu
              size={45}
              className="text-primary-color cursor-pointer py-2"
            />
          ) : (
            <AiOutlineClose
              size={45}
              className="text-primary-color cursor-pointer py-2"
            />
          )}
        </div>
      </div>
      {showNav && (
        <div className={"bg-primary-color backdrop-blur-sm h-screen w-full"}>
          <SiteLang
            onClickLanguage={handleShowNav}
            wrapperClassName="flex justify-end p-5 border-b border-gray-300"
            dropdownClassName="flex flex-row-reverse p-5 absolute right-[50px] top-0"
            dropdownImageClassName="ml-2"
          />

          <ul className="flex flex-col">
            {navbarPages.map((navbarPage) => {
              return (
                <Link
                  onClick={handleShowNav}
                  key={navbarPage.to}
                  to={navbarPage.to}
                  className={`${
                    navbarPage.to === activeLink
                      ? "navbar-responsive-active-page-btn"
                      : "navbar-responsive-page-btn"
                  }`}
                >
                  {translate(navbarPage.name)}
                </Link>
              );
            })}
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
