import { useState, useEffect } from "react";

/**
 * Custom hook to track the current screen dimensions (width, height, and type).
 * @returns {object} - An object with `width`, `height`, and `type` (screen size category).
 */

export const screenDimensions = {
  "2xl": 1536,
  xl: 1280,
  lg: 1024,
  md: 768,
  sm: 0,
};
const useScreenDimensions = () => {
  const getScreenType = (width) => {
    if (width >= 1536) return "2xl";
    if (width >= 1280) return "xl";
    if (width >= 1024) return "lg";
    if (width >= 768) return "md";
    return "sm";
  };

  const [screenDimensions, setScreenDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    type: getScreenType(window.innerWidth),
  });

  useEffect(() => {
    const updateDimensions = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const type = getScreenType(width);

      setScreenDimensions({ width, height, type });
    };

    // Initial dimensions
    updateDimensions();

    // Add event listener for resize
    window.addEventListener("resize", updateDimensions);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return screenDimensions;
};

export default useScreenDimensions;
